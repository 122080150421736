@import "tailwindcss/base";

@import "./typography.css";
@import "tailwindcss/components";

@import "./plyr.css";



@import "tailwindcss/utilities";

@import "./layout.css";
@import "./custom-snip.css";