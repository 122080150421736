:root {
    --samHighlight: #5200A7;
    --samGrey: #EEEEEE;
}

/* purgecss start ignore */

.snipcart * {
    @apply font-basis;
    --color-default: var(--samHighlight);
    --bgColor-badge: var(--samHighlight);
    --color-badge: var(--samGrey);
    --color-badge-active: var(--samHighlight);
    --bgColor-badge-active: var(--samGrey);
    --color-inputIcon: var(--samHighlight);
    --color-icon: var(--samHighlight);
    --color-link: var(--samHighlight);
    --borderColor-input-focus: var(--samHighlight);
    --bgColor-input-checked: var(--samHighlight);
    --borderColor-input-checked: var(--samHighlight);
    --borderColor-input-hover: var(--samHighlight);
    --color-buttonSecondary-hover: var(--samGrey);
    --bgColor-buttonSecondary-active: var(--samHighlight);
    --bgColor-buttonSecondary: var(--samHighlight);
    --color-buttonSecondary: var(--samGrey);
    --bgColor-buttonSecondary-hover: var(--samHighlight);
    
}

.snipcart-button-primary {
    @apply bg-samHighlight;
}

.snipcart-button-primary:hover {
    @apply bg-samGrey text-black shadow-none;
}

.snipcart-cart__secondary-header {
    @apply bg-white;
}

/* purgecss end ignore */