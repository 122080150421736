@font-face {
    font-family: 'basis-grotesque-regular-pro';
    src: url('../fonts/basis-grotesque-regular-pro.eot');
    src: url('../fonts/basis-grotesque-regular-pro.eot?#iefix') format('embedded-opentype'),
         url('../fonts/basis-grotesque-regular-pro.woff2') format('woff2'),
         url('../fonts/basis-grotesque-regular-pro.woff') format('woff'),
         url('../fonts/basis-grotesque-regular-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'basis-grotesque-regular-pro';
    src: url('../fonts/basis-grotesque-italic-pro.eot');
    src: url('../fonts/basis-grotesque-italic-pro.eot?#iefix') format('embedded-opentype'),
         url('../fonts/basis-grotesque-italic-pro.woff2') format('woff2'),
         url('../fonts/basis-grotesque-italic-pro.woff') format('woff'),
         url('../fonts/basis-grotesque-italic-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'basis-grotesque-regular-pro';
    src: url('../fonts/basis-grotesque-bold-pro.eot');
    src: url('../fonts/basis-grotesque-bold-pro.eot?#iefix') format('embedded-opentype'),
         url('../fonts/basis-grotesque-bold-pro.woff2') format('woff2'),
         url('../fonts/basis-grotesque-bold-pro.woff') format('woff'),
         url('../fonts/basis-grotesque-bold-pro.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'basis-grotesque-regular-pro';
    src: url('../fonts/basis-grotesque-bold-italic-pro.eot');
    src: url('../fonts/basis-grotesque-bold-italic-pro.eot?#iefix') format('embedded-opentype'),
         url('../fonts/basis-grotesque-bold-italic-pro.woff2') format('woff2'),
         url('../fonts/basis-grotesque-bold-italic-pro.woff') format('woff'),
         url('../fonts/basis-grotesque-bold-italic-pro.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'basis-grotesque-regular-pro';
    src: url('../fonts/basis-grotesque-medium-pro.eot');
    src: url('../fonts/basis-grotesque-medium-pro.eot?#iefix') format('embedded-opentype'),
         url('../fonts/basis-grotesque-medium-pro.woff2') format('woff2'),
         url('../fonts/basis-grotesque-medium-pro.woff') format('woff'),
         url('../fonts/basis-grotesque-medium-pro.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

